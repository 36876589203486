import React from 'react';

const CardProject = ({image, title, patch }) => {
    return (
        <a href={patch} target="_blank" rel="noopener noreferrer">
        <div className="w-36 h-36 md:w-64 md:h-40 lg:w-48 lg:h-36 xl:w-56 bg-transparent rounded-md border-2 hover:cursor-pointer shadow-md shadow-sky-400 transform hover:scale-105 duration-300 hover:shadow-emerald-400">
            <img src={image} alt="" className='w-full h-28 md:h-32 lg:h-28'/>
            <h1 className="text-center text-[1rem] text-white font-bold">{title} </h1>
        </div>
        </a>
        );
};

export default CardProject;



