import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/home";
import About from "./Pages/about";
import Experience from "./Pages/Experience";
import Contact from "./Pages/contact";
import Project from "./Pages/Project";
import NotFound from "./Pages/NotFound";
import ApiWithRateLimit from "./Components/ApiWithRateLimit";

function App() {
  return (
    <Router>
      <div className="App">
        {/* Menggunakan URL situs web Anda sendiri sebagai apiEndpoint */}
        <ApiWithRateLimit apiEndpoint="https://rchmdi.tech/" />
        
        {/* Definisikan rute-rute halaman */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/project" element={<Project />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
