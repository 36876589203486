import Gambar from '../Assets/images/Assest-4.png'
import GitHubImg from '../Assets/icons/github.png'
import LinkedinImg from '../Assets/icons/linkedin.png'
// import CvImg from '../Assets/icons/account.png'
import InstagramImg from '../Assets/icons/instagram.png'
import Card from '../Components/cardAbout';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'
import Navbar from '../Components/navbar'

const About = () => {
  useEffect(()=> {
    Aos.init({
      duration: 1000,
      delay: 200,
      once: true,
    });
  }, []);

    const cards = [
        {
          image: GitHubImg,
          patch: "https://github.com/anggarchmdi",
        },
        {
          image: LinkedinImg,
          patch: "https://www.linkedin.com/in/anggarchmdi",
        },
        // {
        //   image: CvImg,
        //   patch: "https://drive.google.com/file/d/17sJ2lKBIHQ6v5Alv85YM6NLUEArf3hdN/view?usp=sharing",
        // },
        {
          image: InstagramImg,
          patch: "https://www.instagram.com/jo.rchm/",
        },
      ];

    return(
        <>
        <Navbar />
        <div className="bg-gray-900 md:hidden w-full h-full md:h-screen py-16 md:overflow-y-hidden">
            <div className="container mx-auto p-4 lg:p-8">
            <hr className="w-14 lg:w-20 border-2 border-[#29ADB2]"/>
            <h1 className="text-start text-[#29ADB2] text-[1.7rem] font-bold">About Me</h1>
            <div className="flex flex-wrap-reverse justify-between ml-auto">
            <div className="grid md:grid-cols-2 py-8">
                {/* text */}
                <div className="pt-8">
                    <p className='text-[1.2rem] md:text-[1.3rem] text-justify md:text-start leading-10 text-white'data-aos="fade-up" > Hello! I'm Angga, a passionate and dedicated individual who loves coding and technology. I thrive in creative environments and enjoy turning complex problems into simple, beautiful and intuitive solutions.</p>
                    <br />
                    <p className='text-[1.2rem] md:text-[1.3rem] text-justify md:text-start font-bold text-white' data-aos="fade-up">the following information about me:</p>
               {/* card */}
                <div className="flex justify-start items-center py-8">
                  <div className="grid grid-cols-4 gap-8" data-aos="fade-up">
                    {cards.map((card, index) => (
                      <Card key={index} image={card.image} patch={card.patch} />
                      ))}
                  </div>
                </div>
                </div>
                {/* image */}
                <div className="order-first md:order-last" data-aos="zoom-in-up">
                  <div className="flex justify-center translate-x-8">
                    <img src={Gambar} alt="gambar" className='w-[300px] ml-auto mr-20'/>
                  </div>
                </div>
            </div>
            </div>
            </div>
        </div>
        
        {/* md */}
        <div className="bg-gray-900 hidden md:block w-full h-full md:h-screen py-16 md:overflow-y-hidden">
            <div className="container mx-auto p-4 lg:p-8">
            <hr className="w-14 lg:w-20 border-2 border-[#29ADB2]"/>
            <h1 className="text-start text-[#29ADB2] text-[1.7rem] font-bold">About Me</h1>
            <div className="flex flex-wrap-reverse justify-between ml-auto">
            <div className="grid md:grid-cols-2 py-8">
                {/* text */}
                <div className="pt-8">
                    <p className='text-[1.2rem] md:text-[1.3rem] text-justify md:text-start leading-10 text-white'data-aos="fade-left" > Hello! I'm Angga, a passionate and dedicated individual who loves coding and technology. I thrive in creative environments and enjoy turning complex problems into simple, beautiful and intuitive solutions.</p>
                    <br />
                    <p className='text-[1.2rem] md:text-[1.3rem] text-justify md:text-start font-bold text-white' data-aos="fade-down">the following information about me:</p>
               {/* card */}
                <div className="flex justify-start items-center py-8">
                  <div className="grid grid-cols-4 gap-8" data-aos="fade-up">
                    {cards.map((card, index) => (
                      <Card key={index} image={card.image} patch={card.patch} />
                      ))}
                  </div>
                </div>
                </div>
                {/* image */}
                <div className="order-first md:order-last" data-aos="fade-right">
                    <img src={Gambar} alt="gambar" className='w-[400px] ml-auto mr-20'/>
                </div>
            </div>
            </div>
            </div>
        </div>
        </>
    )
}
export default About;