import React, { useState, useEffect } from 'react';
import { RateLimiter } from 'limiter';

const ApiWithRateLimit = ({ apiEndpoint }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Inisialisasi RateLimiter dengan batasan 50 panggilan per menit
    const limiter = new RateLimiter({ tokensPerInterval: 50, interval: 'minute' });

    // Fungsi untuk memanggil API dengan pembatasan
    const fetchDataWithLimit = async () => {
      // Cek apakah masih tersedia token
      if (limiter.tryRemoveTokens(1)) {
        setIsLoading(true);
        try {
          const response = await fetch(apiEndpoint);
          const result = await response.json();
          setData(result);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.log('Rate limit reached!');
      }
    };

    // Memanggil fetchDataWithLimit setiap kali komponen di-render
    fetchDataWithLimit();

    // Tidak perlu memanggil limiter.stop() di sini

    // Membersihkan limiter saat komponen di-unmount
    // return () => {
    //   limiter.stop();
    // };
  }, [apiEndpoint]);

  return (
    <div>
      {isLoading ? 'Loading...' : (
        // Tampilkan data yang diambil dari API di sini
        data.map(item => (
          <div key={item.id}>{item.name}</div>
        ))
      )}
    </div>
  );
};

export default ApiWithRateLimit;
