import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const Links = [
    { name: "HOME", link: "/" },
    { name: "ABOUT", link: "/about" },
    { name: "EXPERIENCE", link: "/experience" },
    { name: "PROJECT", link: "/project" },
    { name: "CONTACT", link: "/contact" },
  ];
  const [open, setOpen] = useState(false);
  return (
    <div className='shadow-md w-full fixed top-0 left-0 z-50'>
      <div className='md:flex items-center justify-between bg-gray-900 bg-opacity-70 backdrop-blur-lg p-6 md:p-4 md:px-10 px-7'>
        <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-gray-800'>
          <span className='text-3xl text-indigo-600 pt-2'>
          </span>
          <h1 className='text-sky-500 hidden xl:flex'>
            MY PORTFOLIO
          </h1>
        </div>
        
        <div onClick={() => setOpen(!open)} className='text-3xl absolute text-sky-500 right-8 top-4 cursor-pointer md:hidden'>
          <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
        </div>

        <ul className={`md:flex md:items-center md:pb-0 pb-2 absolute md:static bg-gray-900 bg-opacity-70 backdrop-blur-lg md:backdrop-blur-none md:bg-transparent backdrop-filter md:z-auto z-[-5] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-14' : 'top-[-490px]'}`}>
          {
            Links.map((link) => (
              <li key={link.name} className='md:ml-8 text-xl md:my-0 my-4'>
                <a 
                  href={link.link} 
                  className={`text-[1rem] ${location.pathname === link.link ? 'text-sky-300 font-bold' : 'text-sky-500'} hover:text-sky-300 duration-500 font-Lugrasimo`}
                >
                  {link.name}
                </a>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
