import React from 'react';

const Card = ({ image, patch }) => {
  return (
    <a href={patch} target="_blank" rel="noopener noreferrer">
      <div className="w-16 h-16 lg:w-20 lg:h-20 bg-transparent rounded-full border-2 shadow-sm hover:cursor-pointer md:shadow-md  transform hover:scale-105 duration-300 hover:shadow-sky-500">
        <img src={image} alt="" className="w-full" />
      </div>
    </a>
  );
};

export default Card;
